import React, { useState } from 'react';
import { Container, Row, Col, Button, Form, ProgressBar } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import "common/assets/css/main-page.css";
import StepFormHeader from '../containers/Home/StepFormHeader';
import axios from 'axios';
import PhoneInput from 'react-phone-input-2';

import Seo from "components/seo";
import 'react-phone-input-2/lib/style.css';

const TakethehairTest = () => {
    const seoData = {
        title: "Take the Hair Test | Radiance Hair Studio",
        description: "Take the Hair Test at Radiance Hair Studio to discover the best hair solutions for you. Personalized recommendations for hair health and treatments.",
        keywords: ["Take the Hair Test"],
      };
    
    const [step, setStep] = useState(1);
    const [innerStep, setInnerStep] = useState(1);
    const [innerStep1, setInnerStep1] = useState(1);
    const [innerStep2, setInnerStep2] = useState(1);

    const { control, handleSubmit, watch, formState: { errors } } = useForm();
    const [showThankYou, setShowThankYou] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const nextStep = () => setStep(step + 1);
    const prevStep = () => setStep(step - 1);

    const nextInnerStep = () => setInnerStep(innerStep + 1);
    const prevInnerStep = () => setInnerStep(innerStep - 1);

    const nextInnerStep1 = () => setInnerStep1(innerStep1 + 1);
    const prevInnerStep1 = () => setInnerStep1(innerStep1 - 1);

    const nextInnerStep2 = () => setInnerStep2(innerStep2 + 1);
    const prevInnerStep2 = () => setInnerStep2(innerStep2 - 1);


    const [fileName, setFileName] = useState('UPLOAD IMAGE');

    const handleFileChange = (e, field) => {
        const file = e.target.files[0];
        if (file) {
            field.onChange(file);
            setFileName(file.name); // Update state with the file name
        }
    };

    const submitFormData = async (data) => {
        setIsSubmitting(true); // Disable the button
    
        try {
            const token = 'CmcXDorMLw8mj8ZlJI1aE1f2U1foRfn8';

            const formData = new FormData();

            for (const key in data) {
                if (data.hasOwnProperty(key)) {
                    formData.append(key, data[key]);
                }
            }

            const response = await axios.post(
                'https://www.radiancehairstudio.com/api/hairtest.php',
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Token': token 
                    }
                }
            );
    
            console.log(response.data);
            setShowThankYou(true);
            nextStep(); 

            setTimeout(() => {
                window.location.href = '/'; 
            }, 2000); 
    
        } catch (error) {
            console.error('There was a problem with the Axios operation:', error);
        } finally {
            setIsSubmitting(false);
        }
    };
    

    const onSubmit = (data) => {
        console.log(data);
        submitFormData(data);
    };

    const handleNextStep = () => handleSubmit(() => nextStep())();
    const handleNextInnerStep = () => handleSubmit(() => nextInnerStep())();
    const handleNextInnerStep1 = () => handleSubmit(() => nextInnerStep1())();
    const handleNextInnerStep2 = () => handleSubmit(() => nextInnerStep2())();

    const handlePrevInnerStep = () => {
        if (step === 1) {
            if (innerStep > 1) {
                setInnerStep(innerStep - 1);
            } else if (innerStep === 1 && step > 1) {
                setStep(step - 1);
                setInnerStep(8);
            }
        } else if (step === 2) {
            if (innerStep1 > 1) {
                setInnerStep1(innerStep1 - 1);
            } else if (innerStep1 === 1 && step > 1) {
                setStep(step - 1);
                setInnerStep1(4);
            }
        }
        else if (step === 3) {
            if (innerStep2 > 1) {
                setInnerStep2(innerStep2 - 1);
            } else if (innerStep2 === 1 && step > 1) {
                setStep(step - 1);
                setInnerStep2(1);
            }
        }
    };

    // Handler to go to the previous main step
    const handlePrevStep = () => {
        if (step > 1) {
            setStep(step - 1);
            // Optionally reset inner steps
            setInnerStep(1);
            setInnerStep1(1);
        }
    };

    const textContent = [
        {
            title: "About You",
            subtitle: "",
            list: [
                "It's normal to lose 50-100 hairs per day. However, losing more than this consistently can indicate a hair loss issue.",
                "Factors contributing to hair fall include genetics, hormonal changes, medical conditions, stress, poor nutrition, and certain hairstyles or treatments.",
                "30% of individuals under 25 are experiencing hair loss due to irregular sleep patterns."
            ]
        },
        {
            title: "Hair Health",
            subtitle: "",
            list: [
                "By the age of 50, 30 to 50% of men experience male androgenetic alopecia.",
                "29% of women experiencing hair loss report experiencing significant symptoms of depression.",
                "Approximately 25% of men with male pattern baldness begin to exhibit symptoms before the age of 21.",
                "By age 60, the prevalence of hair loss among women is expected to reach approximately 80%."
            ]
        },
        {
            title: "Scalp Assessment",
            subtitle: "",
            list: [
                "Despite the high prevalence of hair loss, only about 20% of those affected seek professional help. Many resort to over-the-counter products, while others explore alternative remedies or accept their condition without intervention.",
                "Men reported a 1.56-fold increase in self-esteem following the use of hair patches and wigs."
            ]
        }
    ];

    return (
        <>
         <Seo {...seoData} />
            <StepFormHeader step={step} setStep={setStep} />

            <section className="stepform-h">
                <Container fluid>
                    <Row>


                        <Col lg={6} className="hero-section text-white order-lg-1 order-2">
                            {textContent[step - 1] && (
                                <>
                                    <h1>{textContent[step - 1].title}</h1>
                                    <h2>{textContent[step - 1].subtitle}</h2>
                                    <ul>
                                        {textContent[step - 1].list.map((item, index) => (
                                            <li key={index}>{item}</li>
                                        ))}
                                    </ul>
                                </>
                            )}
                        </Col>
                        <Col lg={6} className="form-section order-lg-2 order-1">


                            <Form onSubmit={handleSubmit(onSubmit)}>

                                {step === 1 && (
                                    <>
                                        <Row>
                                            <Col className="text-left">
                                                {/* Show Previous Step button based on current step and inner step */}
                                                {(step > 1 || innerStep > 1 || innerStep > 1) && (
                                                    <div
                                                        variant="link"
                                                        onClick={handlePrevInnerStep}
                                                        className="text-brown"
                                                    >
                                                        &larr; Previous Step
                                                    </div>
                                                )}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="step-indicator">
                                                <h3>
                                                    Step 1 <span className="step-description">About You</span>
                                                </h3>
                                                <ProgressBar
                                                    now={(innerStep / 8) * 100}
                                                    className="mb-3 progress-brown"
                                                />
                                            </Col>
                                        </Row>
                                        {innerStep === 1 && (
                                            <>
                                                <h3>Before we start, can we get your name?</h3>
                                                <Form.Group className="form-group">

                                                    <Form.Label htmlFor="name">Your Name, Please</Form.Label>
                                                    <div className="row">
                                                        <div className="col-lg-10 col-md-12">
                                                            <Controller
                                                                name="name"
                                                                control={control}
                                                                defaultValue=""
                                                                rules={{ required: "This cannot be empty. Please enter a value" }}
                                                                render={({ field }) => (
                                                                    <Form.Control
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="name"
                                                                        placeholder="Enter Full Name"
                                                                        {...field}
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                    {errors.name && <small className="text-danger">{errors.name.message}</small>}
                                                </Form.Group>
                                                <div className='col-lg-3 mb-3'>
                                                    <Button className="btn btn-dark" onClick={handleNextInnerStep}>
                                                        Next
                                                    </Button>
                                                </div>
                                            </>
                                        )}

                                        {innerStep === 2 && (
                                            <>
                                                <h3>Your Email</h3>
                                                <Form.Group className="form-group">
                                                    <Form.Label htmlFor="email">Your Email</Form.Label>
                                                    <div className="row">
                                                        <div className="col-lg-10 col-md-12">
                                                            <Controller
                                                                name="email"
                                                                control={control}
                                                                defaultValue=""
                                                                rules={{ required: "This cannot be empty. Please enter a value", pattern: { value: /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/, message: "Invalid email address" } }}
                                                                render={({ field }) => (
                                                                    <Form.Control
                                                                        type="email"
                                                                        className="form-control"
                                                                        id="email"
                                                                        placeholder="Email Email"
                                                                        {...field}
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                    {errors.email && <small className="text-danger">{errors.email.message}</small>}
                                                </Form.Group>
                                                <div className='col-lg-3 mb-3'>
                                                    <Button className="btn btn-dark" onClick={handleNextInnerStep}>
                                                        Next
                                                    </Button>
                                                </div>
                                            </>
                                        )}

                                        {innerStep === 3 && (
                                            <>
                                                <h3>Your Contact Details</h3>
                                                <Form.Group className="form-group">
                                                    <Form.Label htmlFor="phone">Your Phone Number</Form.Label>
                                                    <div className="row">
                                                        <div className="col-lg-10 col-md-12">
                                                            <Controller
                                                                name="phone"
                                                                control={control}
                                                                defaultValue=""
                                                                rules={{
                                                                    required: "This cannot be empty. Please enter a value",
                                                                    validate: value => value.length >= 10 || "Invalid phone number"
                                                                }}
                                                                render={({ field }) => (
                                                                    <PhoneInput
                                                                        country={'in'}
                                                                        value={field.value}
                                                                        onChange={field.onChange}
                                                                        inputStyle={{ width: '100%' }}
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                    {errors.phone && <small className="text-danger">{errors.phone.message}</small>}
                                                </Form.Group>
                                                <div className='col-lg-3 mb-3'>
                                                    <Button className="btn btn-dark" onClick={handleNextInnerStep}>
                                                        Next
                                                    </Button>
                                                </div>
                                                <small className="form-text">
                                                    Your contact detail will be used by Radiance hair coach to reach out to you via call/sms/whats-app.
                                                </small>
                                            </>
                                        )}

                                        {innerStep === 4 && (
                                            <>
                                                <h3>How old are you?</h3>
                                                <Form.Group className="form-group">
                                                    <Form.Label htmlFor="age">Your Age</Form.Label>
                                                    <div className="row">
                                                        <div className="col-lg-10 col-md-12">
                                                            <Controller
                                                                name="age"
                                                                control={control}
                                                                defaultValue=""
                                                                rules={{ required: "This cannot be empty. Please enter a value", pattern: { value: /^[0-9]+$/, message: "Age must be a number" } }}
                                                                render={({ field }) => (
                                                                    <Form.Control
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="age"
                                                                        placeholder="Enter Age"
                                                                        {...field}
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                    {errors.age && <small className="text-danger">{errors.age.message}</small>}
                                                </Form.Group>
                                                <div className='col-lg-3 mb-3'>
                                                    <Button className="btn btn-dark" onClick={handleNextInnerStep}>
                                                        Next
                                                    </Button>
                                                </div>
                                                <small className="form-text">
                                                    Your contact detail will be used by Radiance hair coach to reach out to you via call/sms/whats-app.
                                                </small>
                                            </>
                                        )}

                                        {innerStep === 5 && (
                                            <>
                                                <h3>Gender Identification</h3>
                                                <Form.Group className="form-group">
                                                    <Form.Label htmlFor="gender">Select Your Gender</Form.Label>
                                                    <div className="radio-group gender d-flex justify-content-start">
                                                        
                                                        <div className="radio bg-white p-2">
                                                            <Controller
                                                                name="gender"
                                                                control={control}
                                                                defaultValue=""
                                                                rules={{ required: "This cannot be empty. Please enter a value" }}
                                                                render={({ field }) => (
                                                                    <>
                                                                        <input
                                                                            type="radio"
                                                                            id="male"
                                                                            value="Male"
                                                                            checked={field.value === "Male"}
                                                                            onChange={() => field.onChange("Male")}
                                                                        />
                                                                        <label htmlFor="male" className="text-center border border-grey">
                                                                            <img src="/home/male-student-2-svgrepo-com1.png" alt="Male" className="img-fluid" />
                                                                            <div><span>Male</span> </div>
                                                                        </label>
                                                                    </>
                                                                )}
                                                            />
                                                        </div>
                                                        <div className="radio bg-white p-2 ml-3">
                                                            <Controller
                                                                name="gender"
                                                                control={control}
                                                                defaultValue=""
                                                                render={({ field }) => (
                                                                    <>
                                                                        <input
                                                                            type="radio"
                                                                            id="female"
                                                                            value="female"
                                                                            checked={field.value === "female"}
                                                                            onChange={() => field.onChange("female")}
                                                                        />
                                                                        <label htmlFor="female" className="text-center border border-grey">
                                                                            <img src="/home/female-doctor-2-svgrepo-com1.png" alt="Female" className="img-fluid" />
                                                                            <div><span>Female</span></div>
                                                                        </label>
                                                                    </>
                                                                )}
                                                            />
                                                        </div>
                                                        <div className="radio bg-white p-2 ml-3">
                                                            <Controller
                                                                name="gender"
                                                                control={control}
                                                                defaultValue=""
                                                                render={({ field }) => (
                                                                    <>
                                                                        <input
                                                                            type="radio"
                                                                            id="others"
                                                                            value="others"
                                                                            checked={field.value === "Others"}
                                                                            onChange={() => field.onChange("Others")}
                                                                        />
                                                                        <label htmlFor="others" className="text-center border border-grey">
                                                                            <img src="/home/other.png" alt="Others" className="img-fluid" />
                                                                            <div><span>Others</span></div>
                                                                        </label>
                                                                    </>
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                    {errors.gender && <small className="text-danger">{errors.gender.message}</small>}
                                                </Form.Group>
                                                <div className='col-lg-3 mb-3'>
                                                    <Button className="btn btn-dark" onClick={handleNextInnerStep}>
                                                        Next
                                                    </Button>
                                                </div>
                                            </>
                                        )}

                                        {innerStep === 6 && (
                                            <>
                                                <h3>Which image best describes your hair loss?</h3>
                                                <Form.Group className="form-group">
                                                    <Form.Label htmlFor="hair-loss">Select your hair loss stage</Form.Label>
                                                    <div className="hair-loss-group justify-content-start">
                                                        <div className="row">
                                                            <div className='col-lg-4'>
                                                                <div className="hair-loss bg-white p-2">
                                                                    <Controller
                                                                        name="hairloss"
                                                                        control={control}
                                                                        defaultValue=""
                                                                        rules={{ required: "This cannot be empty. Please enter a value" }}
                                                                        render={({ field }) => (
                                                                            <>
                                                                                <input
                                                                                    type="radio"
                                                                                    id="stage1"
                                                                                    value="Stage1"
                                                                                    checked={field.value === "Stage1"}
                                                                                    onChange={() => field.onChange("Stage1")}
                                                                                />
                                                                                <label htmlFor="stage1" className="text-center border d-flex border-grey">
                                                                                    <img src="/home/stage1.svg" alt="Stage 1" className="img-fluid" />
                                                                                    <div><span>Stage 1</span> </div>
                                                                                </label>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <div className="hair-loss bg-white p-2 ml-3">
                                                                    <Controller
                                                                        name="hairloss"
                                                                        control={control}
                                                                        defaultValue=""
                                                                        render={({ field }) => (
                                                                            <>
                                                                                <input
                                                                                    type="radio"
                                                                                    id="stage2"
                                                                                    value="Stage2"
                                                                                    checked={field.value === "Stage2"}
                                                                                    onChange={() => field.onChange("Stage2")}
                                                                                />
                                                                                <label htmlFor="stage2" className="text-center d-flex border border-grey">
                                                                                    <img src="/home/stage2.svg" alt="Stage 2" className="img-fluid" />
                                                                                    <div><span>Stage 2</span></div>
                                                                                </label>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <div className="hair-loss bg-white p-2 ml-3">
                                                                    <Controller
                                                                        name="hairloss"
                                                                        control={control}
                                                                        defaultValue=""
                                                                        render={({ field }) => (
                                                                            <>
                                                                                <input
                                                                                    type="radio"
                                                                                    id="stage3"
                                                                                    value="Stage3"
                                                                                    checked={field.value === "Stage3"}
                                                                                    onChange={() => field.onChange("Stage3")}
                                                                                />
                                                                                <label htmlFor="stage3" className="text-center d-flex border border-grey">
                                                                                    <img src="/home/stage3.svg" alt="Stage 3" className="img-fluid" />
                                                                                    <div><span>Stage 3</span></div>
                                                                                </label>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-lg-4">
                                                                <div className="hair-loss bg-white p-2 ml-3">
                                                                    <Controller
                                                                        name="hairloss"
                                                                        control={control}
                                                                        defaultValue=""
                                                                        render={({ field }) => (
                                                                            <>
                                                                                <input
                                                                                    type="radio"
                                                                                    id="stage4"
                                                                                    value="Stage4"
                                                                                    checked={field.value === "Stage4"}
                                                                                    onChange={() => field.onChange("Stage4")}
                                                                                />
                                                                                <label htmlFor="stage4" className="text-center d-flex border border-grey">
                                                                                    <img src="/home/stage4.svg" alt="Stage 4" className="img-fluid" />
                                                                                    <div><span>Stage 4</span></div>
                                                                                </label>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <div className="hair-loss bg-white p-2 ml-3">
                                                                    <Controller
                                                                        name="hairloss"
                                                                        control={control}
                                                                        defaultValue=""
                                                                        render={({ field }) => (
                                                                            <>
                                                                                <input
                                                                                    type="radio"
                                                                                    id="stage5"
                                                                                    value="Stage5"
                                                                                    checked={field.value === "Stage5"}
                                                                                    onChange={() => field.onChange("Stage5")}
                                                                                />
                                                                                <label htmlFor="stage5" className="text-center d-flex border border-grey">
                                                                                    <img src="/home/stage5.svg" alt="Stage 5" className="img-fluid" />
                                                                                    <div><span>Stage 5</span></div>
                                                                                </label>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <div className="hair-loss bg-white p-2 ml-3">
                                                                    <Controller
                                                                        name="hairloss"
                                                                        control={control}
                                                                        defaultValue=""
                                                                        render={({ field }) => (
                                                                            <>
                                                                                <input
                                                                                    type="radio"
                                                                                    id="stage6"
                                                                                    value="Stage6"
                                                                                    checked={field.value === "Stage6"}
                                                                                    onChange={() => field.onChange("Stage6")}
                                                                                />
                                                                                <label htmlFor="stage6" className="text-center d-flex border border-grey">
                                                                                    <img src="/home/stage6.svg" alt="Stage 6" className="img-fluid" />
                                                                                    <div><span>Stage 6</span></div>
                                                                                </label>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-lg-4">
                                                                <div className="hair-loss bg-white p-2 ml-3">
                                                                    <Controller
                                                                        name="hairloss"
                                                                        control={control}
                                                                        defaultValue=""
                                                                        render={({ field }) => (
                                                                            <>
                                                                                <input
                                                                                    type="radio"
                                                                                    id="stage7"
                                                                                    value="Stage7"
                                                                                    checked={field.value === "Stage7"}
                                                                                    onChange={() => field.onChange("Stage7")}
                                                                                />
                                                                                <label htmlFor="stage7" className="text-center d-flex border border-grey">
                                                                                    <img src="/home/stage7.svg" alt="Stage 7" className="img-fluid" />
                                                                                    <div><span>Stage 7</span></div>
                                                                                </label>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <div className="hair-loss bg-white p-2 ml-3">
                                                                    <Controller
                                                                        name="hairloss"
                                                                        control={control}
                                                                        defaultValue=""
                                                                        render={({ field }) => (
                                                                            <>
                                                                                <input
                                                                                    type="radio"
                                                                                    id="stage8"
                                                                                    value="Stage8"
                                                                                    checked={field.value === "Stage8"}
                                                                                    onChange={() => field.onChange("Stage8")}
                                                                                />
                                                                                <label htmlFor="stage8" className="text-center d-flex border border-grey">
                                                                                    <img src="/home/stage8.svg" alt="Stage 8" className="img-fluid" />
                                                                                    <div><span>Stage 8</span></div>
                                                                                </label>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>
                                                    {errors.hairloss && <small className="text-danger">{errors.hairloss.message}</small>}
                                                </Form.Group>
                                                <div className='col-lg-3 mb-3'>
                                                    <Button className="btn btn-dark" onClick={handleNextInnerStep}>
                                                        Next
                                                    </Button>
                                                </div>
                                            </>
                                        )}

                                        {innerStep === 7 && (
                                            <>
                                                <h3>What is your hair structure</h3>
                                                <Form.Group className="form-group">

                                                    <div className="familyhistory-group justify-content-start">
                                                        <div className="row">
                                                            <div className="col-lg-4">
                                                                <div className="familyhistory pb-3">
                                                                    <Controller
                                                                        name="hairstructure"
                                                                        control={control}
                                                                        rules={{ required: "This cannot be empty. Please enter a value" }}
                                                                        defaultValue=""
                                                                        render={({ field }) => (
                                                                            <>
                                                                                <input
                                                                                    type="radio"
                                                                                    id="straight"
                                                                                    value="Straight"
                                                                                    checked={field.value === "Straight"}
                                                                                    onChange={() => field.onChange("Straight")}
                                                                                />
                                                                                <label htmlFor="straight" className="text-center d-flex border border-grey">

                                                                                    <div><span>Straight</span></div>
                                                                                </label>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-4">
                                                                <div className="familyhistory pb-3">
                                                                    <Controller
                                                                        name="hairstructure"
                                                                        control={control}
                                                                        defaultValue=""
                                                                        render={({ field }) => (
                                                                            <>
                                                                                <input
                                                                                    type="radio"
                                                                                    id="wavy"
                                                                                    value="Wavy"
                                                                                    checked={field.value === "Wavy"}
                                                                                    onChange={() => field.onChange("Wavy")}
                                                                                />
                                                                                <label htmlFor="wavy" className="text-center d-flex border border-grey">

                                                                                    <div><span>Wavy</span></div>
                                                                                </label>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <div className="familyhistory ">
                                                                    <Controller
                                                                        name="hairstructure"
                                                                        control={control}
                                                                        defaultValue=""
                                                                        render={({ field }) => (
                                                                            <>
                                                                                <input
                                                                                    type="radio"
                                                                                    id="curly"
                                                                                    value="Curly"
                                                                                    checked={field.value === "Curly"}
                                                                                    onChange={() => field.onChange("Curly")}
                                                                                />
                                                                                <label htmlFor="curly" className="text-center d-flex border border-grey">
                                                                                    <div><span>Curly </span></div>
                                                                                </label>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-lg-4">
                                                                <div className="familyhistory ">
                                                                    <Controller
                                                                        name="hairstructure"
                                                                        control={control}
                                                                        defaultValue=""
                                                                        render={({ field }) => (
                                                                            <>
                                                                                <input
                                                                                    type="radio"
                                                                                    id="cioly"
                                                                                    value="Cioly"
                                                                                    checked={field.value === "Cioly"}
                                                                                    onChange={() => field.onChange("Cioly")}
                                                                                />
                                                                                <label htmlFor="cioly" className="text-center d-flex border border-grey">

                                                                                    <div><span>Cioly </span></div>
                                                                                </label>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>
                                                    {errors.hairstructure && <small className="text-danger">{errors.hairstructure.message}</small>}
                                                </Form.Group>
                                                <div className='col-lg-3 mb-3'>
                                                    <Button className="btn btn-dark" onClick={handleNextInnerStep}>
                                                        Next
                                                    </Button>
                                                </div>

                                            </>
                                        )}

                                        {innerStep === 8 && (
                                            <>
                                                <h3>What is your natural hair color?</h3>
                                                <Form.Group className="form-group">
                                                    <Form.Label htmlFor="gender">Select at least one option</Form.Label>
                                                    <div className="familyhistory-group justify-content-start">
                                                        <div className="row">
                                                            <div className="col-lg-4">
                                                                <div className="familyhistory pb-3">
                                                                    <Controller
                                                                        name="haircolor"
                                                                        control={control}
                                                                        rules={{ required: "This cannot be empty. Please enter a value" }}
                                                                        defaultValue=""
                                                                        render={({ field }) => (
                                                                            <>
                                                                                <input
                                                                                    type="radio"
                                                                                    id="black"
                                                                                    value="Black"
                                                                                    checked={field.value === "Black"}
                                                                                    onChange={() => field.onChange("Black")}
                                                                                />
                                                                                <label htmlFor="black" className="text-center d-flex border border-grey">

                                                                                    <div><span>Black</span></div>
                                                                                </label>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-4">
                                                                <div className="familyhistory pb-3">
                                                                    <Controller
                                                                        name="haircolor"
                                                                        control={control}
                                                                        defaultValue=""
                                                                        render={({ field }) => (
                                                                            <>
                                                                                <input
                                                                                    type="radio"
                                                                                    id="brown"
                                                                                    value="Brown"
                                                                                    checked={field.value === "Brown"}
                                                                                    onChange={() => field.onChange("Brown")}
                                                                                />
                                                                                <label htmlFor="brown" className="text-center d-flex border border-grey">

                                                                                    <div><span>Brown</span></div>
                                                                                </label>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <div className="familyhistory ">
                                                                    <Controller
                                                                        name="haircolor"
                                                                        control={control}
                                                                        defaultValue=""
                                                                        render={({ field }) => (
                                                                            <>
                                                                                <input
                                                                                    type="radio"
                                                                                    id="blonde"
                                                                                    value="Blonde"
                                                                                    checked={field.value === "Blonde"}
                                                                                    onChange={() => field.onChange("Blonde")}
                                                                                />
                                                                                <label htmlFor="blonde" className="text-center d-flex border border-grey">

                                                                                    <div><span>Blonde</span></div>
                                                                                </label>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-lg-4">
                                                                <div className="familyhistory ">
                                                                    <Controller
                                                                        name="haircolor"
                                                                        control={control}
                                                                        defaultValue=""
                                                                        render={({ field }) => (
                                                                            <>
                                                                                <input
                                                                                    type="radio"
                                                                                    id="white"
                                                                                    value="White/gray"
                                                                                    checked={field.value === "White/gray"}
                                                                                    onChange={() => field.onChange("White/gray")}
                                                                                />
                                                                                <label htmlFor="white" className="text-center d-flex border border-grey">

                                                                                    <div><span>White/gray</span></div>
                                                                                </label>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-4">
                                                                <div className="familyhistory ">
                                                                    <Controller
                                                                        name="haircolor"
                                                                        control={control}
                                                                        defaultValue=""
                                                                        render={({ field }) => (
                                                                            <>
                                                                                <input
                                                                                    type="radio"
                                                                                    id="other1"
                                                                                    value="Other"
                                                                                    checked={field.value === "Other"}
                                                                                    onChange={() => field.onChange("Other")}
                                                                                />
                                                                                <label htmlFor="other1" className="text-center d-flex border border-grey">

                                                                                    <div><span>Other</span></div>
                                                                                </label>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>
                                                    {errors.haircolor && <small className="text-danger">{errors.haircolor.message}</small>}
                                                </Form.Group>
                                                <div className='col-lg-3 mb-3'>
                                                    <Button className="btn btn-dark" onClick={handleNextStep}>
                                                        Next
                                                    </Button>
                                                </div>

                                            </>
                                        )}
                                    </>
                                )}

                                {step === 2 && (
                                    <>
                                        <Row>
                                            <Col className="text-left">
                                                {/* Show Previous Step button based on current step and inner step */}
                                                {(step > 1 || innerStep > 1 || innerStep1 > 1) && (
                                                    <div
                                                        variant="link"
                                                        onClick={handlePrevInnerStep}
                                                        className="text-brown"
                                                    >
                                                        &larr; Previous Step
                                                    </div>
                                                )}
                                            </Col>
                                        </Row>


                                        <Row>

                                            <Col className="step-indicator">
                                                <h3>
                                                    Step 2 <span className="step-description">Hair Health</span>
                                                </h3>
                                                <ProgressBar
                                                    now={(innerStep1 / 4) * 100}
                                                    className="mb-3 progress-brown"
                                                />
                                            </Col>
                                        </Row>

                                        {innerStep1 === 1 && (
                                            <>
                                                <h3>Is there a history of hair loss in your family?</h3>
                                                <Form.Group className="form-group">
                                                    {/* <Form.Label htmlFor="gender">Select at least one option</Form.Label> */}
                                                    <div className="familyhistory-group justify-content-start">
                                                        <div className="row">
                                                            <div className="col-lg-5">
                                                                <div className="familyhistory pb-3">
                                                                    <Controller
                                                                        name="familyhistory"
                                                                        control={control}
                                                                        rules={{ required: "This cannot be empty. Please enter a value" }}
                                                                        defaultValue=""
                                                                        render={({ field }) => (
                                                                            <>
                                                                                <input
                                                                                    type="radio"
                                                                                    id="familyhistory1"
                                                                                    value="From the mother's side"
                                                                                    checked={field.value === "From the mother's side"}
                                                                                    onChange={() => field.onChange("From the mother's side")}
                                                                                />
                                                                                <label htmlFor="familyhistory1" className="text-center d-flex border border-grey">

                                                                                    <div><span>From the mother's side</span></div>
                                                                                </label>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-5">
                                                                <div className="familyhistory pb-3">
                                                                    <Controller
                                                                        name="familyhistory"
                                                                        control={control}
                                                                        defaultValue=""
                                                                        render={({ field }) => (
                                                                            <>
                                                                                <input
                                                                                    type="radio"
                                                                                    id="familyhistory2"
                                                                                    value="From the father's side"
                                                                                    checked={field.value === "From the father's side"}
                                                                                    onChange={() => field.onChange("From the father's side")}
                                                                                />
                                                                                <label htmlFor="familyhistory2" className="text-center d-flex border border-grey">

                                                                                    <div><span>From the father's side</span></div>
                                                                                </label>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-lg-3">
                                                                <div className="familyhistory pb-3">
                                                                    <Controller
                                                                        name="familyhistory"
                                                                        control={control}
                                                                        defaultValue=""
                                                                        render={({ field }) => (
                                                                            <>
                                                                                <input
                                                                                    type="radio"
                                                                                    id="familyhistory3"
                                                                                    value="Both"
                                                                                    checked={field.value === "Both"}
                                                                                    onChange={() => field.onChange("Both")}
                                                                                />
                                                                                <label htmlFor="familyhistory3" className="text-center d-flex border border-grey">

                                                                                    <div><span>Both</span></div>
                                                                                </label>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-3">
                                                                <div className="familyhistory ">
                                                                    <Controller
                                                                        name="familyhistory"
                                                                        control={control}
                                                                        defaultValue=""
                                                                        render={({ field }) => (
                                                                            <>
                                                                                <input
                                                                                    type="radio"
                                                                                    id="familyhistory4"
                                                                                    value="None"
                                                                                    checked={field.value === "None"}
                                                                                    onChange={() => field.onChange("None")}
                                                                                />
                                                                                <label htmlFor="familyhistory3" className="text-center d-flex border border-grey">

                                                                                    <div><span>None</span></div>
                                                                                </label>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    {errors.familyhistory && <small className="text-danger">{errors.familyhistory.message}</small>}
                                                </Form.Group>
                                                <div className='col-lg-3 mb-3'>
                                                    <Button className="btn btn-dark" onClick={handleNextInnerStep1}>
                                                        Next
                                                    </Button>
                                                </div>
                                            </>
                                        )}

                                        {innerStep1 === 2 && (
                                            <>
                                                <h3>Do you have dandruff?</h3>
                                                <Form.Group className="form-group">
                                                    <div className="familyhistory-group justify-content-start">
                                                        <div className="row">
                                                            <div className="col-lg-3">
                                                                <div className="familyhistory pb-3">
                                                                    <Controller
                                                                        name="dandruff"
                                                                        control={control}
                                                                        rules={{ required: "This cannot be empty. Please enter a value" }}
                                                                        defaultValue=""
                                                                        render={({ field }) => (
                                                                            <>
                                                                                <input
                                                                                    type="radio"
                                                                                    id="dandruff1"
                                                                                    value="Yes"
                                                                                    checked={field.value === "Yes"}
                                                                                    onChange={() => field.onChange("Yes")}
                                                                                />
                                                                                <label htmlFor="dandruff1" className="text-center d-flex border border-grey">
                                                                                    <div><span>Yes</span></div>
                                                                                </label>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-6">
                                                                <div className="familyhistory pb-3">
                                                                    <Controller
                                                                        name="dandruff"
                                                                        control={control}
                                                                        defaultValue=""
                                                                        render={({ field }) => (
                                                                            <>
                                                                                <input
                                                                                    type="radio"
                                                                                    id="dandruff2"
                                                                                    value="I have Seborrheic Dermatitis"
                                                                                    checked={field.value === "I have Seborrheic Dermatitis"}
                                                                                    onChange={() => field.onChange("I have Seborrheic Dermatitis")}
                                                                                />
                                                                                <label htmlFor="dandruff2" className="text-center d-flex border border-grey">
                                                                                    <div><span>I have Seborrheic Dermatitis</span></div>
                                                                                </label>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* Conditionally render additional options if "Yes" is selected */}
                                                        {watch('dandruff') === "Yes" && (
                                                            <div className="row">
                                                                <div className="col-lg-5">
                                                                    <div className="familyhistory pb-3">
                                                                        <Controller
                                                                            name="dandruffDetails"
                                                                            control={control}
                                                                            rules={{ required: "This cannot be empty. Please enter a value" }}
                                                                            defaultValue=""
                                                                            render={({ field }) => (
                                                                                <>
                                                                                    <input
                                                                                        type="radio"
                                                                                        id="dandruffDetails1"
                                                                                        value="Mild that comes and goes"
                                                                                        checked={field.value === "Mild that comes and goes"}
                                                                                        onChange={() => field.onChange("Mild that comes and goes")}
                                                                                    />
                                                                                    <label htmlFor="dandruffDetails1" className="text-center d-flex border border-grey">
                                                                                        <div><span>Mild that comes and goes</span></div>
                                                                                    </label>
                                                                                </>
                                                                            )}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="col-lg-7">
                                                                    <div className="familyhistory pb-3">
                                                                        <Controller
                                                                            name="dandruffDetails"
                                                                            control={control}
                                                                            defaultValue=""
                                                                            render={({ field }) => (
                                                                                <>
                                                                                    <input
                                                                                        type="radio"
                                                                                        id="dandruffDetails2"
                                                                                        value="Heavy dandruff that sticks to the scalp"
                                                                                        checked={field.value === "Heavy dandruff that sticks to the scalp"}
                                                                                        onChange={() => field.onChange("Heavy dandruff that sticks to the scalp")}
                                                                                    />
                                                                                    <label htmlFor="dandruffDetails2" className="text-center d-flex border border-grey">
                                                                                        <div><span>Heavy dandruff that sticks to the scalp</span></div>
                                                                                    </label>
                                                                                </>
                                                                            )}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}

                                                        <div className="row">
                                                            <div className="col-lg-6">
                                                                <div className="familyhistory pb-3">
                                                                    <Controller
                                                                        name="dandruff"
                                                                        control={control}
                                                                        defaultValue=""
                                                                        render={({ field }) => (
                                                                            <>
                                                                                <input
                                                                                    type="radio"
                                                                                    id="dandruff3"
                                                                                    value="I have Psoriasis"
                                                                                    checked={field.value === "I have Psoriasis"}
                                                                                    onChange={() => field.onChange("I have Psoriasis")}
                                                                                />
                                                                                <label htmlFor="dandruff3" className="text-center d-flex border border-grey">
                                                                                    <div><span>I have Psoriasis</span></div>
                                                                                </label>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-3">
                                                                <div className="familyhistory">
                                                                    <Controller
                                                                        name="dandruff"
                                                                        control={control}
                                                                        defaultValue=""
                                                                        render={({ field }) => (
                                                                            <>
                                                                                <input
                                                                                    type="radio"
                                                                                    id="dandruff4"
                                                                                    value="No"
                                                                                    checked={field.value === "No"}
                                                                                    onChange={() => field.onChange("No")}
                                                                                />
                                                                                <label htmlFor="dandruff4" className="text-center d-flex border border-grey">
                                                                                    <div><span>No</span></div>
                                                                                </label>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {errors.dandruff && <small className="text-danger">{errors.dandruff.message}</small>}
                                                    {errors.dandruffDetails && <small className="text-danger">{errors.dandruffDetails.message}</small>}
                                                </Form.Group>

                                                <div className='col-lg-3 mb-3'>
                                                    <Button className="btn btn-dark" onClick={handleNextInnerStep1}>
                                                        Next
                                                    </Button>
                                                </div>
                                            </>
                                        )}

                                        {innerStep1 === 3 && (
                                            <>
                                                <h3>Did you have a hair transplant earlier?</h3>
                                                <Form.Group className="form-group">
                                                    {/* <Form.Label htmlFor="gender">Select at least one option</Form.Label> */}
                                                    <div className="familyhistory-group justify-content-start">
                                                        <div className="row">
                                                            <div className="col-lg-3">
                                                                <div className="familyhistory pb-3">
                                                                    <Controller
                                                                        name="hairtransplantearlier"
                                                                        control={control}
                                                                        rules={{ required: "This cannot be empty. Please enter a value" }}
                                                                        defaultValue=""
                                                                        render={({ field }) => (
                                                                            <>
                                                                                <input
                                                                                    type="radio"
                                                                                    id="hairtransplantearlier1"
                                                                                    value="Yes"
                                                                                    checked={field.value === "Yes"}
                                                                                    onChange={() => field.onChange("Yes")}
                                                                                />
                                                                                <label htmlFor="hairtransplantearlier1" className="text-center d-flex border border-grey">

                                                                                    <div><span>Yes</span></div>
                                                                                </label>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-3">
                                                                <div className="familyhistory pb-3">
                                                                    <Controller
                                                                        name="hairtransplantearlier"
                                                                        control={control}
                                                                        defaultValue=""
                                                                        render={({ field }) => (
                                                                            <>
                                                                                <input
                                                                                    type="radio"
                                                                                    id="hairtransplantearlier2"
                                                                                    value="No"
                                                                                    checked={field.value === "No"}
                                                                                    onChange={() => field.onChange("No")}
                                                                                />
                                                                                <label htmlFor="hairtransplantearlier2" className="text-center d-flex border border-grey">

                                                                                    <div><span>No</span></div>
                                                                                </label>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {errors.hairtransplantearlier && <small className="text-danger">{errors.hairtransplantearlier.message}</small>}
                                                </Form.Group>
                                                <div className='col-lg-3 mb-3'>
                                                    <Button className="btn btn-dark" onClick={handleNextInnerStep1}>
                                                        Next
                                                    </Button>
                                                </div>
                                            </>
                                        )}

                                        {innerStep1 === 4 && (
                                            <>
                                                <h3>How well do you sleep?</h3>
                                                <Form.Group className="form-group">
                                                    {/* <Form.Label htmlFor="gender">Select at least one option</Form.Label> */}
                                                    <div className="familyhistory-group justify-content-start">
                                                        <div className="row">
                                                            <div className="col-lg-10">
                                                                <div className="familyhistory pb-3">
                                                                    <Controller
                                                                        name="sleep"
                                                                        control={control}
                                                                        rules={{ required: "This cannot be empty. Please enter a value" }}
                                                                        defaultValue=""
                                                                        render={({ field }) => (
                                                                            <>
                                                                                <input
                                                                                    type="radio"
                                                                                    id="sleep1"
                                                                                    value="6 to 8 hours of peaceful sleep"
                                                                                    checked={field.value === "6 to 8 hours of peaceful sleep"}
                                                                                    onChange={() => field.onChange("6 to 8 hours of peaceful sleep")}
                                                                                />
                                                                                <label htmlFor="sleep1" className="text-center d-flex border border-grey">

                                                                                    <div><span>6 to 8 hours of peaceful sleep</span></div>
                                                                                </label>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-10">
                                                                <div className="familyhistory pb-3">
                                                                    <Controller
                                                                        name="sleep"
                                                                        control={control}
                                                                        defaultValue=""
                                                                        render={({ field }) => (
                                                                            <>
                                                                                <input
                                                                                    type="radio"
                                                                                    id="sleep2"
                                                                                    value="Disturbed sleep, with at least one time waking up during the night"
                                                                                    checked={field.value === "Disturbed sleep, with at least one time waking up during the night"}
                                                                                    onChange={() => field.onChange("Disturbed sleep, with at least one time waking up during the night")}
                                                                                />
                                                                                <label htmlFor="sleep2" className="text-center d-flex border border-grey">

                                                                                    <div><span>Disturbed sleep, with at least one time waking up during the night</span></div>
                                                                                </label>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-10">
                                                                <div className="familyhistory pb-3">
                                                                    <Controller
                                                                        name="sleep"
                                                                        control={control}
                                                                        defaultValue=""
                                                                        render={({ field }) => (
                                                                            <>
                                                                                <input
                                                                                    type="radio"
                                                                                    id="sleep3"
                                                                                    value="Have difficulty falling asleep"
                                                                                    checked={field.value === "Have difficulty falling asleep"}
                                                                                    onChange={() => field.onChange("Have difficulty falling asleep")}
                                                                                />
                                                                                <label htmlFor="sleep3" className="text-center d-flex border border-grey">

                                                                                    <div><span>Have difficulty falling asleep</span></div>
                                                                                </label>
                                                                            </>
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    {errors.sleep && <small className="text-danger">{errors.sleep.message}</small>}
                                                </Form.Group>
                                                <div className='col-lg-3 mb-3'>
                                                    <Button className="btn btn-dark" onClick={handleNextStep}>
                                                        Next
                                                    </Button>
                                                </div>
                                            </>
                                        )}
                                    </>
                                )}

                                {step === 3 && (
                                    <>
                                        <Row>
                                            <Col className="text-left">
                                                {/* Show Previous Step button based on current step and inner step */}
                                                {(step > 1 || innerStep > 1 || innerStep2 > 1) && (
                                                    <div
                                                        variant="link"
                                                        onClick={handlePrevInnerStep}
                                                        className="text-brown"
                                                    >
                                                        &larr; Previous Step
                                                    </div>
                                                )}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="step-indicator">
                                                <h3>
                                                    Step 3 <span className="step-description">Scalp Assessment</span>
                                                </h3>
                                                <ProgressBar
                                                    now={(innerStep2 / 1) * 100}
                                                    className="mb-3 progress-brown"
                                                />
                                            </Col>
                                        </Row>

                                        {innerStep2 === 1 && (
                                            <>
                                                <h3>Upload your scalp photo.</h3>
                                                <span>After you place the order, this photo will be used by</span>
                                                <ol>
                                                    <li>Doctors: To analyze and prescribe your kit’s dosage.</li>
                                                    <li>Hair Coaches: To track your hair progress.</li>
                                                </ol>
                                                <Form.Group className="form-group mb-0">
                                                    <div className="col-lg-5">
                                                        <div className="file-upload-group">

                                                            <Controller
                                                                name="fileUpload"
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <>
                                                                        <input
                                                                            type="file"
                                                                            id="fileUpload"
                                                                            onChange={(e) => handleFileChange(e, field)}
                                                                        />
                                                                        <label htmlFor="fileUpload" className="d-block mt-3">
                                                                            <img src="/home/Hair-Loss-Chart_4-1024x5671.svg" alt="Upload icon" />
                                                                            <h1>{fileName}</h1>
                                                                            <span>Take a selfie of your hair and upload</span>
                                                                        </label>
                                                                    </>
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                </Form.Group>
                                                <div className="col-lg-6 mb-2">
                                                <span className="text-red">*Image is not mandatory</span>
                                                </div>
                                                <div className='col-lg-3 mb-3'>
                                                
                                                    <Button
                                                        type='submit'
                                                        className="btn btn-dark"
                                                        disabled={isSubmitting} // Disable the button while submitting
                                                    >
                                                        Submit
                                                    </Button>
                                                </div>
                                            </>
                                        )}
                                    </>
                                )}

                            </Form>
                        </Col>
                    </Row>
                    {showThankYou && (
            <div className="thankyou-popup">
              <div className="thankyou-content">
                <p>Thank you for your submission!</p>
                <button className="close-btn" onClick={() => setShowThankYou(false)}>Close</button>
              </div>
            </div>
          )}

                </Container>
            </section>
        </>
    );
};
export default TakethehairTest;
