import React, { useState, useEffect } from "react";
import { Container, StepformheaderWrapperCSS } from "./stepformheader.style";
import logo from "common/assets/image/interior/radiance-logo.png";
import NavbarWrapper from "common/components/Navbar";
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faCheck } from "@fortawesome/free-solid-svg-icons";

const StepFormHeader = ({ step, setStep, formCompleted = {} }) => {
    const [canProceed, setCanProceed] = useState(true);

    useEffect(() => {
        setCanProceed(formCompleted[step] || false);
    }, [step, formCompleted]);

    return (
        <StepformheaderWrapperCSS>
            <NavbarWrapper className="mainNav">
                <Container className="mainHeadernav">
                    <nav className="myContainer navbar navbar-expand-lg navbar-dark">
                        <Link to="/">
                            <img
                                src={logo}
                                width="170"
                                height="44"
                                alt="Radiance Hair Studio"
                                className="logoWrapper radianceMainLogo"
                            />
                        </Link>
                        <button
                            className="navbar-toggler"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarNav"
                            aria-controls="navbarNav"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <FontAwesomeIcon icon={faBars} />
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNav">
                            <ul className="navbar-nav mx-auto">
                                <li className={`nav-item pl-2 pr-2 ${step === 1 ? "active" : ""}`}>
                                    <Link className="nav-link" to="#" onClick={() => setStep(1)}>
                                        {step === 1 && <FontAwesomeIcon icon={faCheck} />} 01 About You
                                    </Link>
                                </li>
                                <li className={`nav-item pl-2 pr-2 ${step === 2 ? "active" : ""}`}>
                                    <Link className="nav-link" to="#" onClick={() => canProceed && setStep(2)}>
                                        {step === 2 && <FontAwesomeIcon icon={faCheck} />} 02 Hair Health
                                    </Link>
                                </li>
                         
                                <li className={`nav-item pl-2 pr-2 ${step === 3 ? "active" : ""}`}>
                                    <Link className="nav-link" to="#" onClick={() => canProceed && setStep(3)}>
                                        {step === 3 && <FontAwesomeIcon icon={faCheck} />} 03 Scalp Assessment
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </Container>
            </NavbarWrapper>
        </StepformheaderWrapperCSS>
    );
};

export default StepFormHeader;
